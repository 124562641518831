import { Link, ProductItem as ProductItemType } from "../../Types/types";
import { ArrowUpRight } from "lucide-react";
import { convertImageUrlToThumbnail } from "../../Utils/functions";
import ReactGA from "react-ga4";
interface ProductItemProps {
  item: ProductItemType;
}
const handleLinkClick = (link: Link) => {
  ReactGA.event({
    category: 'User',
    action: 'Clicked on a product PDF',
    label: link.url.split("/").pop() // gets title of pdf
  });
  window.open(link.url, "_blank")
}
const ProductItem: React.FC<ProductItemProps> = ({ item }) => (
  <div
    className="group h-full bg-white border border-gray-200 transition-all 
    duration-300 hover:shadow-lg rounded-md overflow-hidden flex flex-col"
  >
    {/* Image container */}
    <div className="p-3 sm:p-4 md:p-6 flex justify-center bg-white">
      <div className="relative w-full h-32 sm:h-40 md:h-48 flex items-center justify-center">
        <img
          src={convertImageUrlToThumbnail(item.image)}
          alt={item.description}
          className="max-h-full max-w-full w-auto object-contain 
            transition-transform duration-500 group-hover:scale-105"
        />
      </div>
    </div>

    <div className="text-center p-3 sm:p-4 md:p-5 border-t border-gray-200 flex flex-col flex-1">
      <h3
        className="text-lg sm:text-xl font-bold text-content 
        mb-3 sm:mb-4 md:mb-5 transition-colors"
      >
        {item.description}
      </h3>
      <div className="mt-auto">
        {item.linksSections.map((section, index) => (
          <div key={index} className="mb-3 sm:mb-4 last:mb-0">
            {section.title && (
              <h4 className="text-xs font-medium text-content-secondary mb-1.5 sm:mb-2">
                {section.title}
              </h4>
            )}
            <div className="flex flex-wrap justify-center gap-1 sm:gap-1.5">
              {section.links.map((link, linkIndex) => (
                <button
                  key={linkIndex}
                  onClick={() => handleLinkClick(link)}
                  className="group/link inline-flex items-center gap-1 bg-gray-100 
                  hover:bg-brand-accentLight text-content-secondary 
                  px-2 sm:px-3 py-1 sm:py-1.5 text-xs transition-colors"
                >
                  {link.title}
                  <ArrowUpRight
                    className="w-2 h-2 sm:w-2.5 sm:h-2.5 
                  transition-transform duration-300 
                  group-hover/link:translate-x-0.5 
                  group-hover/link:-translate-y-0.5"
                  />
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default ProductItem;
