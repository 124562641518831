import React, { useState } from "react";
import { ProductSection } from "../../Types/types";
import { getContrastColor, scrollToSection } from "../../Utils/functions";
import { useLocation } from "react-router";

interface PageHeaderProps {
  title: string;
  description?: string;
  descriptionColor?: string;
  backgroundImage?: string;
  sections?: ProductSection[];
  isProduct?: boolean;
  abbreviation?: string;
  color?: string;
  gallery?: boolean;
  applications?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  description,
  descriptionColor = "white",
  backgroundImage,
  sections,
  isProduct = false,
  abbreviation,
  color,
  gallery,
  applications,
}) => {
  const location = useLocation();
  // https://files.modtruss.com/files/mt_trussgeneric.jpg
  const defaultBackgroundImage =
    "https://files.modtruss.com/files/mt_trussgeneric.jpg";
  const usedBackgroundImage = backgroundImage ?? defaultBackgroundImage;
  const usingDefaultBackgroundImage =
    usedBackgroundImage === defaultBackgroundImage;

  const isMainPage = () => {
    const pathname = location.pathname;
    return pathname.split("/").length - 1 === 1;
  };

  const hasNavigation = sections?.length > 0 || gallery || applications;

  const handleNavClick = (sectionTitle: string) => {
    scrollToSection(sectionTitle);
  };

  return (
    <>
      <div
        id="pageHeader"
        className={`${
          !usingDefaultBackgroundImage
            ? "relative"
            : "relative min-h-[300px] sm:min-h-[350px] md:min-h-[400px] flex items-center"
        }`}
      >
        {usingDefaultBackgroundImage ? (
          <div className="absolute inset-0 z-0">
            <div
              className="absolute inset-0 bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${defaultBackgroundImage})` }}
            />
            <div className={`absolute inset-0 bg-brand-light/90`} />
          </div>
        ) : (
          <div className="w-full">
            <div className={`absolute inset-0 bg-brand-light/30`} />
            <img
              src={usedBackgroundImage}
              alt="Header background"
              className="w-full "
            />
          </div>
        )}

        {/* Content Container - Absolute positioned over the image */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div
            className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full ${
              !usingDefaultBackgroundImage ? "text-left" : "text-center"
            }`}
          >
            <div
              className={`flex items-center ${
                !usingDefaultBackgroundImage
                  ? "justify-left w-2/3"
                  : "justify-center w-full"
              } gap-2 sm:gap-3 md:gap-4 mb-4 sm:mb-6 md:mb-8`}
            >
              {isProduct && abbreviation && color && (
                <div
                  className={`w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 flex items-center justify-center 
                text-xl sm:text-2xl md:text-3xl font-medium border ${getContrastColor(
                  color
                )}`}
                  style={{ backgroundColor: color }}
                >
                  {abbreviation}
                </div>
              )}
              <h1
                className={`text-white text-3xl sm:text-5xl md:text-6xl lg:text-7xl 
              ${isMainPage() ? "font-bold" : "font-semibold"} break-words`}
              >
                {title}
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Sticky Navigation */}
      {hasNavigation && (
        <div className="top-16 sm:top-20 md:top-24 z-30 w-full bg-white shadow-md border-b transition-all duration-300">
          <div className="max-w-7xl mx-auto md:px-12">
            <div className="flex flex-wrap items-center justify-center gap-x-3 gap-y-1 py-1">
              {sections?.map((section) => (
                <button
                  key={section.buttonText}
                  className="px-4 py-2 text-sm text-gray-600 hover:text-content
                    hover:bg-gray-50 transition-colors whitespace-nowrap font-medium
                    border-b-2 border-transparent hover:border-[var(--main-yellow)]"
                  onClick={() => handleNavClick(section.title)}
                >
                  {section.buttonText}
                </button>
              ))}
              {gallery && (
                <button
                  className="px-4 py-3 text-sm text-gray-600 hover:text-content 
                    hover:bg-gray-50 transition-colors whitespace-nowrap font-medium
                    border-b-2 border-transparent hover:border-[var(--main-yellow)]"
                  onClick={() => handleNavClick("gallery")}
                >
                  Gallery
                </button>
              )}
              {applications && (
                <button
                  className="px-4 py-3 text-sm text-gray-600 hover:text-content 
                    hover:bg-gray-50 transition-colors whitespace-nowrap font-medium
                    border-b-2 border-transparent hover:border-[var(--main-yellow)]"
                  onClick={() => handleNavClick("applications")}
                >
                  Applications
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PageHeader;
