import React from "react";
import PageHeader from "../General/PageHeader";
import LeadershipSection from "./LeadershipSection";
import AwardsSection from "./AwardsSection";
import AboutTextSection from "./AboutTextSection";
import Divider from "../General/Divider";
import { SEO } from "../General/SEO";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const About: React.FC = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: "About",
        });
    }, []);
    return (
        <div className="min-h-screen bg-background">
            <SEO
                title={"About - ModTruss"}
                description={
                    "Learn about ModTruss, our leadership, and why we are the right fit for your needs."
                }
                path={"/about"}
            />
            <PageHeader
                title="About Us"
                description="Building the future of modular structural solutions"
            />
            <AboutTextSection />
            <Divider />
            <LeadershipSection />
            <Divider />
            <AwardsSection />
        </div>
    );
};

export default About;
