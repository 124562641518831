import React, { useEffect, useState } from "react";
import PageHeader from "../General/PageHeader";
import { Product } from "../../Types/types";
import LoadingSpinner from "../General/LoadingSpinner";
import ProductSection from "./ProductSection";
import { useLocation, useNavigate } from "react-router";
import Divider from "../General/Divider";
import { ArrowRight, Download } from "lucide-react";
import { useProducts } from "../../Providers/ProductProvider";
import { useApplications } from "../../Providers/ApplicationProvider";
import { convertTitleToUrl, convertUrlToTitle } from "../../Utils/functions";
import PicturesGrid from "../General/PicturesGrid";
import ItemCard from "../General/ItemCard";
import { SEO } from "../General/SEO";
import ReactGA from "react-ga4";

const ProductPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { getProductByName, isLoading: isProviderLoading } = useProducts();
    const { applications } = useApplications();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [product, setProduct] = useState<Product | null>(null);

    const getPageIdentifier = (pathname: string): string => {
        const segments = pathname.split("/").filter(Boolean);
        return segments[segments.length - 1];
    };

    useEffect(() => {
        if (!isProviderLoading) {
            const identifier = getPageIdentifier(location.pathname);
            const currentProduct = getProductByName(
                convertUrlToTitle(identifier)
            );

            if (currentProduct) {
                ReactGA.send({
                    hitType: "pageview",
                    page: window.location.pathname,
                    title: currentProduct.name,
                });
                setProduct(currentProduct);
            } else {
                navigate("/404", { replace: true });
            }
            setIsPageLoading(false);
        }
    }, [location.pathname, getProductByName, navigate, isProviderLoading]);

    if (isProviderLoading || isPageLoading) {
        return <LoadingSpinner fullScreen />;
    }

    if (!product) {
        return null;
    }

    const associatedApplications = applications.filter((app) =>
        product.associatedApplications.includes(app.id)
    );

    return (
        <div>
            <SEO
                title={`${product.name} - Modtruss`}
                description={product.description}
                path={`/products/${convertTitleToUrl(product.name)}`}
            />
            <PageHeader
                title={product.pageHeaderTitle}
                description={product.pageHeaderDescription}
                sections={product.productSections}
                isProduct={true}
                abbreviation={product.abbreviation}
                color={product.color}
                gallery={product.pictures && product.pictures.length > 0}
                applications={associatedApplications.length > 0}
                backgroundImage={product.banner}
            />

            <div className="py-12 sm:py-16 md:py-20 lg:py-24 bg-gray-50">
                <div className="max-w-7xl mx-auto px-4">
                    {/* Product Sections */}
                    {product.productSections?.map((section, index) => (
                        <div
                            key={index}
                            id={section.title}
                            className="scroll-mt-24 sm:scroll-mt-28 md:scroll-mt-32 lg:scroll-mt-40"
                        >
                            <ProductSection section={section} />
                            {index !== product.productSections.length - 1 && (
                                <Divider />
                            )}
                        </div>
                    ))}

                    {/* Catalog Button */}
                    <div className="text-center">
                        <button
                            onClick={() => window.open("/catalog", "_blank")}
                            className="group bg-brand-accent text-brand-accentText 
                px-6 py-4 text-base font-medium 
                hover:bg-brand-accentHover transition-all duration-300 
                inline-flex items-center gap-3 rounded-none shadow-sm hover:shadow-md border"
                        >
                            <Download className="w-5 h-5" />
                            VIEW/DOWNLOAD THE CATALOG (V5)
                            <ArrowRight
                                className="w-5 h-5 transition-transform duration-300 
                                   group-hover:translate-x-1"
                            />
                        </button>
                    </div>

                    {/* Pictures Section */}
                    {product.pictures && product.pictures.length > 0 && (
                        <>
                            <Divider />
                            <div className="scroll-mt-24 sm:scroll-mt-28 md:scroll-mt-32 lg:scroll-mt-40 mb-16">
                                <div className="max-w-7xl mx-auto">
                                    <div className="text-center mb-8">
                                        <div className="flex items-center justify-center gap-2">
                                            <h2
                                                id="Gallery"
                                                className="text-2xl sm:text-3xl md:text-4xl font-bold md:mb-1"
                                            >
                                                Product Gallery
                                            </h2>
                                        </div>
                                    </div>
                                    <PicturesGrid pictures={product.pictures} />
                                </div>
                            </div>
                        </>
                    )}

                    {/* Applications Section */}
                    {associatedApplications.length > 0 && (
                        <>
                            <Divider />
                            <div className="scroll-mt-24 sm:scroll-mt-28 md:scroll-mt-32 lg:scroll-mt-40 mb-16">
                                <div className="max-w-7xl mx-auto">
                                    <div className="text-center mb-8">
                                        <div className="flex items-center justify-center gap-2">
                                            <h2
                                                id="applications"
                                                className="text-2xl sm:text-3xl md:text-4xl font-bold md:mb-1"
                                            >
                                                Common Applications
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                        {associatedApplications.map(
                                            (application) => (
                                                <ItemCard
                                                    key={application.id}
                                                    item={application}
                                                />
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductPage;
