import React from "react";
import PageHeader from "../General/PageHeader";
import ProductShowcase from "./ProductShowcase";
import { SEO } from "../General/SEO";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const Products: React.FC = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "All Products" });
  }, []);
  
  return (
    <div>
      <SEO
        title={"Products - ModTruss"}
        description={
          "Explore our diverse product line designed to meet all your construction needs."
        }
        path={"/products"}
      />
      <PageHeader title="Our Products" />
      <ProductShowcase />
    </div>
  );
};

export default Products;
