import React from "react";
import PageHeader from "../General/PageHeader";

interface LoadTableItem {
  id: string;
  type: string;
  title: string;
  description: string;
  url: string;
}

interface LoadTableData {
  id: number;
  title: string;
  type: string;
  items: LoadTableItem[];
}

const loadTablesData: LoadTableData = {
  id: 2,
  title: "Load Tables",
  type: "data",
  items: [
    {
      id: "toolkit-1",
      type: "truss",
      title: '3" STEEL LOAD TABLE',
      description: "Load table for the 3 inch steel truss",
      url: "https://files.modtruss.com/files/MT-TR-03-03-XXX-AL Load Tables.pdf",
    },
    {
      id: "toolkit-2",
      type: "truss",
      title: '6" ALUMINUM LOAD TABLE',
      description: "Load table for the 6 inch aluminum truss",
      url: "https://files.modtruss.com/files/tech.catalog_v6_6.13.19_06_al_tr_loadtables.pdf",
    },
    {
      id: "toolkit-2",
      type: "truss",
      title: '6" STEEL LOAD TABLE',
      description: "Load table for the 6 inch steel truss",
      url: "https://files.modtruss.com/files/tech.catalog_v6_6.13.19_06_st_tr_loadtables.pdf",
    },
    {
      id: "toolkit-2",
      type: "truss",
      title: '12" ALUMINUM LOAD TABLE',
      description: "Load table for the 12 inch aluminum truss",
      url: "https://files.modtruss.com/files/tech.catalog_v6_6.13.19_12_al_tr_loadtables.pdf",
    },
    {
      id: "toolkit-2",
      type: "truss",
      title: '12" STEEL LOAD TABLE',
      description: "Load table for the 12 inch steel truss",
      url: "https://files.modtruss.com/files/tech.catalog_v6_3.28.19_12_st_tr_loadtables.pdf",
    },
    {
      id: "toolkit-2",
      type: "extrusion",
      title: '3"X3" ALUMINUM LOAD TABLE',
      description: "This is a 3 inch extrusion load table",
      url: "https://files.modtruss.com/files/tech.catalog_v6_3.28.19_03_ex_loadtables.pdf",
    },
    {
      id: "toolkit-2",
      type: "extrusion",
      title: '6"X6" ALUMINUM LOAD TABLE',
      description: "This is a 6 inch aluminum extrusion load table",
      url: "https://files.modtruss.com/files/6_x6_-aluminum-extrusion-load-table-updated.pdf",
    },
    {
      id: "toolkit-2",
      type: "extrusion",
      title: '6"X12" ALUMINUM LOAD TABLE',
      description: "This is a 6 by 12 inch aluminum extrusion load table",
      url: "https://files.modtruss.com/files/6_x12_-aluminum-extrusion-load-table.pdf",
    },
  ],
};

const groupedTables = {
  truss: loadTablesData.items.filter((item) => item.type === "truss"),
  extrusion: loadTablesData.items.filter((item) => item.type === "extrusion"),
};

const TableSection: React.FC<{ title: string; items: LoadTableItem[] }> = ({
  title,
  items,
}) => (
  <div className="mb-16">
    <h2 className="text-2xl sm:text-3xl font-bold mb-8 text-center">
      {title} Load Tables
    </h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {items.map((item) => (
        <a
          key={`${item.id}-${item.title}`}
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          className="group bg-white border border-gray-200 rounded-lg shadow-sm 
                   hover:shadow-lg hover:border-brand-accent transition-all duration-300 
                   cursor-pointer p-6"
        >
          <div className="flex items-start gap-4">
            <div>
              <h3
                className="text-lg font-semibold mb-2 
                           transition-colors duration-300"
              >
                {item.title}
              </h3>
              <p className="text-gray-600 text-sm">{item.description}</p>
            </div>
          </div>
        </a>
      ))}
    </div>
  </div>
);

const LoadTables: React.FC = () => {
  return (
    <div>
      <PageHeader title="Load Tables" />
      <div className="py-8 sm:py-12 md:py-16 lg:py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <TableSection title="Truss" items={groupedTables.truss} />
          <TableSection title="Extrusion" items={groupedTables.extrusion} />
        </div>
      </div>
    </div>
  );
};

export default LoadTables;
