import React, { useEffect } from "react";
import PageHeader from "../General/PageHeader";
import { SEO } from "../General/SEO";
import ReactGA from "react-ga4";

interface PolicySection {
  id: string;
  title: string;
  content: string | string[];
}

const policies: PolicySection[] = [
  {
    id: "credit",
    title: "Credit And Payments",
    content:
      "It is the policy of ModTruss Inc. that payment be received with the order unless other arrangements have been made in advance. Credit may be extended to those customers who have successfully completed a credit application. Down payments may be required from customers (including those with credit approval) for raw material purchases, custom products, or engineering costs. In such cases the ordering of materials and fabrication will not proceed until payment has been received. Credit card payments are subject to a 2% fee.",
  },
  {
    id: "warranty",
    title: "Product Warranty",
    content: [
      "ModTruss Inc. guarantees it products will be free from defects in workmanship and materials at the time of purchase. All products by certified welders-certificates available upon request. Product should be inspected by customer upon receipt and any defects reported to ModTruss Inc. within a 24 hour period. The product will be guaranteed, for a period of one year from date of shipment, to perform according to the product's published specifications providing the purchaser follows the guidelines for use. ModTruss Inc. is not responsible for:",
      "Damage to the product during transit",
      "Damage to the product where load parameters were exceeded",
      "Damage to the product where guidelines for use, handling, or storage were not followed",
      "Normal wear and tear on product",
    ],
  },
  {
    id: "finishes",
    title: "Finishes",
    content: [
      'The standard finish of the aluminum ModTruss Truss Series (MT-TR) is a "brushed finish". Light markings or minor scratching from manufacturing process, handling, or shipping does not deem the product defective or unusable.',
      "Anodizing of the ModTruss Extrusion Series (MT-EX) is a finish that is applied prior to processes such as cutting or drilling. Minor scuffing or scratching from cutting or drilling of the Extrusion is to be expected.",
    ],
  },
  {
    id: "returns",
    title: "Return Policy",
    content: [
      "A return of any ModTruss product must be authorized in advance by ModTruss Inc. Returned goods without prior authorization will not be accepted. ModTruss Inc. is not obligated for return freight expenses unless the return is the result of the fault of ModTruss Inc. or unless otherwise pre-approved by ModTruss Inc.",
      "Contact ModTruss Inc. with any questions concerning the policies stated above.",
    ],
  },
];

const WarrantyAndPolicies: React.FC = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Warranty & Policies" });
  }, []);

  return (
    <div className="min-h-screen bg-background">
      <SEO
        title={"Warranty & Policies"}
        description={
          "Read through our warranty information and policies. Connect to us if you have any questions."
        }
        path={"/warranty-any-policies"}
      />
      <PageHeader title="Warranty & Policies" />

      <div
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 
          py-8 sm:py-12 md:py-16"
      >
        <div className="space-y-3 sm:space-y-4">
          {policies.map((policy) => (
            <div key={policy.id} className="bg-white shadow-lg">
              <div className="p-4 sm:p-6 md:p-8">
                {/* Title with accent bar */}
                <div className="relative mb-4 sm:mb-6 md:mb-8">
                  <div
                    className="absolute -left-4 sm:-left-6 md:-left-8 
                      top-0 bottom-0 w-1 sm:w-1.5 md:w-2 bg-brand-accent"
                  />
                  <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-content">
                    {policy.title}
                  </h2>
                </div>

                {/* Content */}
                <div
                  className="space-y-3 sm:space-y-4 text-content-secondary 
                    text-sm sm:text-base"
                >
                  {Array.isArray(policy.content) ? (
                    <>
                      <p className="leading-relaxed">{policy.content[0]}</p>
                      {policy.content.slice(1).map((item, index) => (
                        <div
                          key={index}
                          className="flex items-start gap-2 sm:gap-3"
                        >
                          {policy.id === "warranty" && index < 4 && (
                            <div
                              className="w-1 h-1 sm:w-1.5 sm:h-1.5 
                                rounded-full bg-content flex-shrink-0 mt-2"
                            />
                          )}
                          <p className="leading-relaxed">{item}</p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <p className="leading-relaxed">{policy.content}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WarrantyAndPolicies;
