import React, { useEffect, useState } from "react";
import { Mail, Phone, Clock, MapPin } from "lucide-react";
import api from "../../Utils/api";
import { SEO } from "../General/SEO";
import ModTrussBolding from "../General/ModTrussBolding";
import ReactGA from "react-ga4";

interface LocationConfig {
    country: string;
    address: string;
    address2: string;
    address3?: string;
    phone: string;
    email: string;
    hours: string;
}

interface ContactConfig {
    title: string;
    subjectOptions: string[];
    formFields: {
        id: string;
        label: string;
        type: string;
        required: boolean;
        placeholder?: string;
        rows?: number;
    }[];
}

const locations: LocationConfig[] = [
    {
        country: "USA",
        address: "7741 Commercial Lane",
        address2: "Allenton, WI 53002",
        phone: "+1.844.663.8787",
        email: "info@ModTruss.com",
        hours: "MON-FRI 8:00 a.m. - 5:30 p.m. CST",
    },
    {
        country: "Europe",
        address: "Ryssviksvägen 2",
        address2: "131 36 Nacka",
        address3: "Stockholm , Sweden",
        phone: "+46 705209909",
        email: "infoeu@ModTruss.com",
        hours: "MON-FRI 8:00 a.m. - 6:00 p.m. CET",
    },
];

const config: ContactConfig = {
    title: "We'd Like To Hear From You!",
    subjectOptions: [
        "Request a Quote",
        "Become a Reseller",
        "Connect with a Reseller",
        "General Information",
    ],
    formFields: [
        {
            id: "fullName",
            label: "Full Name",
            type: "text",
            required: true,
        },
        {
            id: "companyName",
            label: "Company Name",
            type: "text",
            required: true,
        },
        {
            id: "stateCountry",
            label: "State/Country",
            type: "text",
            required: true,
        },
        {
            id: "phone",
            label: "Phone Number",
            type: "tel",
            required: true,
            placeholder: "+1(123) 456-7890",
        },
        {
            id: "email",
            label: "Your Email",
            type: "email",
            required: true,
        },
        {
            id: "subject",
            label: "Subject",
            type: "text",
            required: true,
        },
        {
            id: "message",
            label: "Please explain what you are looking for:",
            type: "textarea",
            required: false,
            rows: 4,
        },
    ],
};

const ContactPage: React.FC = () => {
    const [formData, setFormData] = useState({
        fullName: "",
        companyName: "",
        stateCountry: "",
        phone: "",
        email: "",
        subject: "",
        message: "",
    });
    const [status, setStatus] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: "Contact",
        });

        // Load the reCAPTCHA script
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=6Lce4Y0qAAAAAPZhCNnzSFymeBbgb1A958HDkIt9`;
        document.body.appendChild(script);

        // Initialize reCAPTCHA once the script loads
        script.onload = () => {
            window.grecaptcha.ready(() => {
                console.log("reCAPTCHA is ready");
            });
        };
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        setStatus("");

        ReactGA.event({
            category: "User",
            action: "Clicked Contact Form Submit",
        });

        try {
            // Wait for reCAPTCHA to be ready
            await new Promise<void>((resolve) => {
                if (window.grecaptcha) {
                    resolve();
                } else {
                    window.grecaptcha = {
                        ready: (cb: () => void) => {
                            resolve();
                            cb();
                        },
                    } as any;
                }
            });

            const token = await window.grecaptcha.execute(
                "6Lce4Y0qAAAAAPZhCNnzSFymeBbgb1A958HDkIt9",
                {
                    action: "submit_contact",
                }
            );

            const response = await api.post("/api/contact", {
                ...formData,
                captchaToken: token,
            });

            if (response.status === 200) {
                setFormData({
                    fullName: "",
                    companyName: "",
                    stateCountry: "",
                    phone: "",
                    email: "",
                    subject: "",
                    message: "",
                });
                setStatus("Message sent successfully!");
            }
        } catch (error) {
            // console.error("Contact form error:", error);
            const errorMessage =
                error.response?.data?.message ||
                error.message ||
                "Failed to send message";
            setStatus(errorMessage);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const renderFormField = (field: (typeof config.formFields)[0]) => {
        const commonClasses =
            "mt-1 w-full p-2.5 sm:p-3 border border-gray-300 text-sm sm:text-base focus:border-content focus:ring-1 focus:ring-brand-accent outline-none transition-all";

        if (field.type === "textarea") {
            return (
                <textarea
                    id={field.id}
                    rows={field.rows}
                    required={field.required}
                    placeholder={field.placeholder}
                    className={commonClasses}
                    value={formData[field.id as keyof typeof formData]}
                    onChange={handleChange}
                />
            );
        }

        if (field.id === "subject") {
            return (
                <>
                    <input
                        type="text"
                        id={field.id}
                        required={field.required}
                        list="subject-options"
                        className={commonClasses}
                        value={formData[field.id as keyof typeof formData]}
                        onChange={handleChange}
                    />
                    <datalist id="subject-options">
                        {config.subjectOptions.map((option) => (
                            <option key={option} value={option} />
                        ))}
                    </datalist>
                </>
            );
        }

        return (
            <input
                type={field.type}
                id={field.id}
                required={field.required}
                placeholder={field.placeholder}
                className={commonClasses}
                value={formData[field.id as keyof typeof formData]}
                onChange={handleChange}
            />
        );
    };

    return (
        <div className="min-h-screen bg-white">
            <SEO
                title={"Contact Us - ModTruss"}
                description={
                    "Reach out to use to learn more, request a quote, become a reseller, or contact a reseller."
                }
                path={"/contact-us"}
            />
            <div className="bg-black w-full pb-4 shadow-lg shadow-black/50">
                <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8 w-full p-4">
                    {locations.map((location, index) => (
                        <div
                            key={index}
                            className="text-white p-6 sm:p-12 border border-gray-700 rounded flex flex-col items-center aspect-[4/2]"
                        >
                            <div className="flex flex-col items-center space-y-1">
                                <div className="text-4xl sm:text-6xl tracking-wide">
                                    Mod
                                    <span className="font-extrabold relative">
                                        Truss
                                        <sup className="absolute text-sm -right-3 top-[25%]">
                                            ®
                                        </sup>
                                    </span>
                                </div>
                                <h3 className="text-4xl sm:text-6xl font-bold uppercase mt-2">
                                    {location.country}
                                </h3>
                                <div className="flex flex-col items-center mt-6 space-y-1">
                                    <div className="flex items-center">
                                        <MapPin className="mr-2" size={20} />{" "}
                                        {location.address}
                                    </div>
                                    <p>{location.address2}</p>
                                    {location.address3 ? (
                                        <p>{location.address3}</p>
                                    ) : (
                                        <p>&nbsp;</p>
                                    )}
                                </div>
                                <div className="flex flex-col items-center mt-4 space-y-3">
                                    <div className="flex items-center">
                                        <Phone className="mr-2" size={20} />{" "}
                                        {location.phone}
                                    </div>
                                    <div className="flex items-center">
                                        <Mail className="mr-2" size={20} />
                                        <ModTrussBolding
                                            text={location.email}
                                        />
                                    </div>
                                    <div className="flex items-center">
                                        <Clock className="mr-2" size={20} />{" "}
                                        {location.hours}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="max-w-7xl mx-auto py-8 sm:py-12 md:py-12 lg:py-16">
                <h2 className="text-2xl sm:text-5xl font-bold text-center mb-8">
                    {config.title}
                </h2>
                <div className="bg-white p-4 sm:p-6 md:p-8">
                    <form
                        onSubmit={handleSubmit}
                        className="space-y-4 sm:space-y-6"
                    >
                        <div className="space-y-3 sm:space-y-4">
                            {config.formFields.map((field) => (
                                <div key={field.id}>
                                    <label className="text-xs sm:text-sm font-medium text-content-secondary">
                                        {field.label}{" "}
                                        {field.required && (
                                            <span className="text-red-500">
                                                *
                                            </span>
                                        )}
                                    </label>
                                    {renderFormField(field)}
                                </div>
                            ))}
                        </div>

                        {status && (
                            <p
                                className={`text-sm ${
                                    status.includes("success")
                                        ? "text-green-600"
                                        : "text-red-600"
                                }`}
                            >
                                {status}
                            </p>
                        )}

                        <div className="pt-2 sm:pt-4">
                            <button
                                type="submit"
                                className="w-[25%] mx-auto bg-brand-accent text-brand-accentText p-3 sm:p-4 
                    text-sm sm:text-base font- hover:bg-brand-accentHover 
                    transition-colors flex items-center justify-center gap-2 border"
                            >
                                {isSubmitting ? "SENDING..." : "SUBMIT"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
