import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App.tsx";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ProductProvider } from "./Providers/ProductProvider.tsx";
import { ApplicationProvider } from "./Providers/ApplicationProvider.tsx";
import { FileProvider } from "./Providers/FileProvider.tsx";
import { ImageCacheProvider } from "./Providers/CacheImageProvider.tsx";

createRoot(document.getElementById("root")!).render(
    <SnackbarProvider maxSnack={3}>
        <ProductProvider>
            <ApplicationProvider>
                <ImageCacheProvider>
                    <BrowserRouter>
                        <FileProvider>
                            <App />
                        </FileProvider>
                    </BrowserRouter>
                </ImageCacheProvider>
            </ApplicationProvider>
        </ProductProvider>
    </SnackbarProvider>
);
