import { useEffect } from "react";
import LoadingSpinner from "./LoadingSpinner";
import ReactGA from "react-ga4";
interface ExternalRedirectProps {
  to: string;
  title: string;
}

const ExternalRedirect: React.FC<ExternalRedirectProps> = ({ to, title }) => {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });
  }, []);

  return (
    <div className="min-h-screen w-full flex items-center justify-center">
      <LoadingSpinner fullScreen />
    </div>
  );
};

export default ExternalRedirect;
