import { Navigate } from "react-router-dom";
import Home from "../Components/Home/Home";
import Products from "../Components/Products/Products";
import ProductPage from "../Components/ProductPage/ProductPage";
// import ManageProducts from "../Components/ManageProducts/ManageProducts";
// import CreateProduct from "../Components/ManageProducts/CreateProduct";
// import EditProduct from "../Components/ManageProducts/EditProduct";
import Applications from "../Components/Applications/Applications";
import ApplicationPage from "../Components/ApplicationPage/ApplicationPage";
// import ManageApplications from "../Components/ManageApplications.tsx/ManageApplications";
// import CreateApplication from "../Components/ManageApplications.tsx/CreateApplication";
// import EditApplication from "../Components/ManageApplications.tsx/EditApplication";
// import Manage from "../Components/Manage/Manage";
import Resources from "../Components/Resources/Resources";
import About from "../Components/About/About";
import Contact from "../Components/Contact/Contact";
import Leadership from "../Components/Leadership/Leadership";
import WarrantyAndPolicies from "../Components/WarrantyAndPolicies/WarrantyAndPolicies";
// import ManageFiles from "../Components/ManageFiles/ManageFiles";
import NotFound from "../Components/General/NotFound";
// import Sitemap from "../Components/General/Sitemap";
import ExternalRedirect from "../Components/General/ExternalRedirect";
import LoadTables from "../Components/LoadTables/LoadTables";

export const routes = [
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/products",
        element: <Products />,
    },
    {
        path: "/products/:category",
        element: <ProductPage />,
    },
    // {
    //   path: "/manage-products",
    //   element: <ManageProducts />,
    // },
    // {
    //   path: "/manage-products/create",
    //   element: <CreateProduct />,
    // },
    // {
    //   path: "/manage-products/edit/:productId",
    //   element: <EditProduct />,
    // },
    {
        path: "/applications",
        element: <Applications />,
    },
    {
        path: "/applications/:category",
        element: <ApplicationPage />,
    },
    // {
    //   path: "/manage-applications",
    //   element: <ManageApplications />,
    // },
    // {
    //   path: "/manage-applications/create",
    //   element: <CreateApplication />,
    // },
    // {
    //   path: "/manage-applications/edit/:applicationId",
    //   element: <EditApplication />,
    // },
    // {
    //   path: "/manage",
    //   element: <Manage />,
    // },
    {
        path: "/resources",
        element: <Resources />,
    },
    {
        path: "/about",
        element: <About />,
    },
    {
        path: "/contact-us",
        element: <Contact />,
    },
    {
        path: "/leadership",
        element: <Leadership />,
    },
    {
        path: "/warranty-and-policies",
        element: <WarrantyAndPolicies />,
    },
    // {
    //   path: "/manage-files",
    //   element: <ManageFiles />,
    // },
    {
        path: "/load-tables",
        element: <LoadTables />,
    },
    {
        path: "/404",
        element: <NotFound />,
    },
    {
        path: "/catalog",
        element: (
            <ExternalRedirect
                to="https://modtruss.com/ModTruss_Catalog.pdf"
                title="Catalog"
            />
        ),
    },
    {
        path: "/connection-guide",
        element: (
            <ExternalRedirect
                to="https://modtruss.com/ModTruss_Connection_Guide.pdf"
                title="Connection Guide"
            />
        ),
    },
    {
        path: "*",
        element: <Navigate to="/" />,
    },
];
