import React, { useEffect, useState } from "react";
import PageHeader from "../General/PageHeader";
import LoadingSpinner from "../General/LoadingSpinner";
import { useLocation, useNavigate } from "react-router";
import { Application } from "../../Types/types";
import ApplicationText from "./ApplicationText";
import { ArrowLeft } from "lucide-react";
import PicturesGrid from "../General/PicturesGrid";
import {
    convertTitleToUrl,
    convertUrlToTitle,
    handleNavigation,
} from "../../Utils/functions";
import { useApplications } from "../../Providers/ApplicationProvider";
import { SEO } from "../General/SEO";
import ReactGA from "react-ga4";

const ApplicationPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { getApplicationByName, isLoading: isProviderLoading } =
        useApplications();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [application, setApplication] = useState<Application | null>(null);

    const getPageIdentifier = (pathname: string): string => {
        const segments = pathname.split("/").filter(Boolean);
        return segments[segments.length - 1];
    };

    useEffect(() => {
        // Only proceed if the provider is ready
        if (!isProviderLoading) {
            const identifier = getPageIdentifier(location.pathname);
            const currentApplication = getApplicationByName(
                convertUrlToTitle(identifier)
            );

            if (currentApplication) {
                ReactGA.send({
                    hitType: "pageview",
                    page: window.location.pathname,
                    title: currentApplication.name,
                });
                setApplication(currentApplication);
            } else {
                navigate("/404", { replace: true });
            }
            setIsPageLoading(false);
        }
    }, [location.pathname, getApplicationByName, navigate, isProviderLoading]);

    // Show loading state while either provider or page is loading
    if (isProviderLoading || isPageLoading) {
        return <LoadingSpinner fullScreen />;
    }

    // Return null if no application (will redirect to 404)
    if (!application) {
        return null;
    }

    return (
        <div className="min-h-screen bg-background">
            <SEO
                title={`${application.name}`}
                description={application.description}
                path={`/applications/${convertTitleToUrl(application.name)}`}
            />
            <PageHeader
                title={application.pageHeaderTitle}
                description={application.pageHeaderDescription}
                backgroundImage={application.banner}
            />

            {/* Back Button Section */}
            <div className="relative -mt-4 sm:-mt-6 md:-mt-8 z-10">
                <div className="max-w-7xl mx-auto px-4">
                    <button
                        onClick={() => handleNavigation("/applications")}
                        className="group inline-flex items-center gap-1.5 sm:gap-2 
             bg-brand-accent border text-brand-accentText 
             px-4 sm:px-5 md:px-6 py-2.5 sm:py-3 text-sm sm:text-base
             hover:bg-brand-accentHover transition-all duration-200"
                    >
                        <ArrowLeft
                            className="w-4 h-4 sm:w-4.5 sm:h-4.5 md:w-5 md:h-5 
             transition-transform group-hover:-translate-x-1"
                        />
                        Back to Applications
                    </button>
                </div>
            </div>

            {/* Application Text Section */}
            <div className="pt-8 sm:pt-10 md:pt-12 bg-background">
                <div className="max-w-7xl mx-auto px-4">
                    <ApplicationText text={application.text} />
                </div>
            </div>

            {/* Pictures Grid Section */}
            <div className="max-w-7xl mx-auto px-4 py-8 sm:py-12 md:py-16 bg-background">
                <PicturesGrid pictures={application.pictures} />
            </div>
        </div>
    );
};

export default ApplicationPage;
